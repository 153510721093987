import {useEffect, useState} from 'react';
// material-ui
import {
    Box,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    Modal,
    OutlinedInput,
    Stack
} from "@mui/material";

// project import
import UserTable from './UsersTable';
import MainCard from 'components/MainCard';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import AnimateButton from '../../components/@extended/AnimateButton';
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {
    useGetMeQuery,
    useAddUserMutation,
    useGetUsersQuery,
    useGetDashboardDataQuery,
    useCreateUserDashboardRelationMutation
} from "../../store/api";
import Loader from "../../components/Loader";

// ==============================|| USERS - DEFAULT ||============================== //

const UsersDefault = () => {
    const [open, setOpen] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [userCounters, setUserCounters] = useState({activeUsers: '0', totalUsers: '0'});

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addUser, { isLoading }] = useAddUserMutation();
    const [addAssociation, { isLoading: isAssociationLoading }] = useCreateUserDashboardRelationMutation()

    let {data: Me} = useGetMeQuery();
    const {data: users, userError} = useGetUsersQuery();
    const {data: dashboards, dashboardError} = useGetDashboardDataQuery();
    useEffect(() => {
        if (Me) { setCompanyId(Me.company_id); }
    }, [Me])

    useEffect(() => {
        let activeUserCounter = 0;
        let monthlySessions = 0;
        let totalSessions = 0;
        const currentDate = new Date();
        if (users) {
            for (const user of users) {
                let updated_at = new Date(user.updated_at);
                if (currentDate.getMonth() - updated_at.getMonth() <= 1){
                    activeUserCounter += 1;
                }
                for (const session of user.sessions) {
                    let session_date = new Date(session.created_at);
                    if (currentDate.getMonth() - session_date.getMonth() <= 1){
                        monthlySessions += 1;
                    }
                }
                totalSessions += user.sessions.length
            }
            setUserCounters(
        {
                activeUsers: activeUserCounter.toString(),
                totalUsers: users.length.toString(),
                totalSessions: totalSessions.toString(),
                monthlySessions: monthlySessions.toString(),

            });
        }
    }, [users])

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <AnalyticEcommerce title="Total Users" count={userCounters.totalUsers} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <AnalyticEcommerce title="Active Users This Month" count={userCounters.activeUsers} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <AnalyticEcommerce title="Total Sessions" count={userCounters.totalSessions} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <AnalyticEcommerce title="Sessions This Month" count={userCounters.monthlySessions} />
            </Grid>
            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

            {/* row 3*/}
            <Grid item xs={12} md={12} lg={12}>

                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={false}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleOpen}
                            >
                                Add User
                            </Button>
                        </AnimateButton>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            disableScrollLock={true}
                        >
                            <Box sx={{ pt: 2.25, backgroundColor: "white", width: "90%", margin: "0 auto",
                                marginTop: "100px", padding: "20px", maxWidth: "600px"
                            }}>
                                <Formik
                                    initialValues={{
                                        company_id: companyId,
                                        name: '',
                                        email: '',
                                        verified: false,
                                        role: 3,
                                        submit: null,
                                        checked: [],
                                    }}
                                    validationSchema={Yup.object().shape({
                                        company_id: Yup.string().max(255).required("Company ID is required"),
                                        name: Yup.string().min(4).max(255).required("Name is required"),
                                        email: Yup.string().email('Must be a valid email').min(8).max(255).required('Email is required'),
                                        checked: Yup.array().min(0)
                                    })}
                                    // onSubmit={(data => console.log(data))}
                                    onSubmit={async (data) => {
                                        try {
                                            addUser(data).unwrap()
                                                .then((returned) => {
                                                    if (returned) {
                                                        const userId = returned['details'];
                                                        for (const selectedDashboardId of data['checked']) {
                                                            addAssociation({userId: userId, selectedDashboardId: selectedDashboardId});
                                                        }
                                                        setOpen(false);
                                                    }
                                                })
                                                .catch((err) => {
                                                    console.error(err);
                                                });
                                        }
                                        catch (e) {
                                            console.error(e)
                                            setServerError(e.data.detail[0].msg)
                                        }

                                        return true;
                                    }}
                                >
                                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                        <form noValidate onSubmit={handleSubmit}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Stack spacing={1}>
                                                        <InputLabel htmlFor="name-user">Name</InputLabel>
                                                        <OutlinedInput
                                                            id="name-user"
                                                            type="name"
                                                            value={values.name}
                                                            name="name"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="Name"
                                                            fullWidth
                                                            error={Boolean(touched.name && errors.name)}
                                                        />
                                                        {touched.name && errors.name && (
                                                            <FormHelperText error id="standard-weight-helper-text-name-user">
                                                                {errors.name}
                                                            </FormHelperText>
                                                        )}
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Stack spacing={1}>
                                                        <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                                        <OutlinedInput
                                                            id="email-user"
                                                            type="email"
                                                            value={values.email}
                                                            name="email"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            placeholder="Enter email address"
                                                            fullWidth
                                                            error={Boolean(touched.email && errors.email)}
                                                        />
                                                        {touched.email && errors.email && (
                                                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                                                {errors.email}
                                                            </FormHelperText>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Stack spacing={1}>
                                                        <span>Dashboards</span>
                                                        <Grid container spacing={2} columns={18}>
                                                        {dashboards.map(dashboard =>
                                                            <Grid item xs={18} sm={9} md={6}>
                                                                <InputLabel htmlFor="name-dashboard">
                                                                    <Field type="checkbox" name="checked" value={dashboard.id.toString()} style={{position: 'absolute', height: '20px', width: '20px'}} />
                                                                    <p style={{paddingLeft: '32px', marginTop: '3px'}}>
                                                                        {dashboard.name}
                                                                    </p>
                                                                </InputLabel>
                                                            </Grid>
                                                        )}
                                                        </Grid>
                                                        {touched.dashboard && errors.dashboard && (
                                                            <FormHelperText error id="standard-weight-helper-text-name-user">
                                                                {errors.dashboard}
                                                            </FormHelperText>
                                                        )}
                                                    </Stack>
                                                </Grid>

                                                {serverError && (
                                                    <Grid item xs={12}>
                                                        <FormHelperText error>Error: {serverError}</FormHelperText>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <AnimateButton>
                                                        {(!isLoading && !isAssociationLoading) ? (
                                                            <Button
                                                                disableElevation
                                                                disabled={isSubmitting}
                                                                fullWidth
                                                                size="large"
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                            >
                                                                Create User
                                                            </Button>
                                                        ) : (
                                                            <Loader />
                                                        )}
                                                    </AnimateButton>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </Box>
                        </Modal>
                    </Grid>
                    <Grid item />
                </Grid>
                <MainCard sx={{mt: 2}} content={false}>
                    <UserTable/>
                </MainCard>
            </Grid>
        </Grid>

    );
};

export default UsersDefault;
