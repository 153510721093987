import { lazy } from 'react';
// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Users from '../pages/users';
import Dashboards from '../pages/dashboard/admin-dashboard';
import Dashboard from '../pages/dashboard/admin-dashboard/Dashboard';
import Connections from '../pages/connections';
import Settings from '../pages/settings';
import AdminAccountSettingsDefault from "../pages/admin-account-settings";
import UserAccountSettingsDefault from "../pages/user-account-settings";

// render - dashboard
const Default = Loadable(lazy(() => import('pages/landing-page')));


// render - utilities

const Color = Loadable(lazy(() => import('pages/components-overview/Color')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/admin',
    element: <MainLayout />,
    children: [
        {
            path: '',
            element: <Default />
        },
        {
            path: 'color',
            element: <Color />
        },
        {
            path: 'user',
            element: <Users />
        },
        {
            path: 'user/:id',
            element: <UserAccountSettingsDefault />
        },
        {
            path: 'bi-dashboards',
            element: <Dashboards />
        },
        {
            path: 'dashboards/:id',
            element: <Dashboard />
        },
        {
            path: 'connections',
            element: <Connections />
        },
        {
            path: 'settings',
            element: <Settings />
        },
        // {
        //     path: 'sample',
        //     element: <SampleDashboard />
        // },
        {
            path: 'account-settings',
            element: <AdminAccountSettingsDefault />
        },
    ]
};

export default AdminRoutes;
