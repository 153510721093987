import * as React from "react";
import getBranding from "../../store/services/get-branding";

const branding = getBranding();

export default function UserHome()
    {
        return (
            branding ? (<html lang="en" style={{boxSizing: 'border-box'}}>
            <head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/*The above 3 meta tags *must* come first in the head; any other head content must come *after* these tags*/}
                <title>CastleBI</title>
                {/*Google font*/}
                <link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,900" rel="stylesheet" />
            </head>
            <body style={{padding: 0, margin: 0}}>
            <div id="notfound" style={{position: 'relative', height: '100vh', backgroundColor: branding.primary_colour}}>
                <div className="notfound" style={{
                    position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -100%)',
                    maxWidth: '920px', width: '100%', lineHeight: 1.4, textAlign: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                    <h2 style={{fontFamily: 'Public Sans, sans-serif', color: branding.secondary_colour, fontWeight: 900, fontSize: '40px', textTransform: 'uppercase', margin: 0}}>
                        Dashboard Portal
                    </h2>
                    <hr style={{color: branding.secondary_colour}} />
                    <p style={{fontFamily: 'Public Sans, sans-serif', color: branding.secondary_colour, fontWeight: 400, fontSize: '14px', marginTop: '15px'}}>
                        You do not have permissions to view any Quicksight dashboards. <br />
                        Please ask your administrator to update your dashboard permissions.
                    </p>
                </div>
            </div>
            </body>
            </html>) :  ( <></> ))
    };

