import React, { useState } from 'react';

import getBranding from "../../../store/services/get-branding";
import DashboardDrawer from "./DashboardDrawer";


// ==============================|| USERS - DEFAULT ||============================== //

const NavHeader = () => {

    const branding = getBranding();

    return (
        <div>
            <div style={{float: 'left', height: '65px', width: '65px', paddingLeft: '13px'}}>
                <DashboardDrawer />
            </div>

            <div style={{backgroundColor: branding.primary_colour, paddingLeft: 10, paddingRight: 10, paddingBottom: 10, height: 65}}>
                <br />
                <div align={'center'} style={{marginTop: '-38px', marginRight: '100px'}}>
                    <h1 style={{color: branding.secondary_colour}}> {branding.title} </h1>
                </div>
                <img src={branding.logo_path} alt={''} style={{float: 'right', maxHeight: '50px', marginTop: '-70px'}}/>
            </div>
        </div>
    );
};

export default NavHeader;
