import { useRoutes } from 'react-router-dom';

// project import
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';
import MainRoute from "./MainRoute";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AdminRoutes, MainRoute, ClientRoutes]);
}
