import React, { useState } from 'react';

import NavHeader from "./NavHeader";
import DashboardQuicksight from "./DashboardQuicksight";


// ==============================|| USERS - DEFAULT ||============================== //

const DashboardsDefault = () => {

    return (
        <div style={{"height": "inherit"}}>
            <NavHeader />
            <DashboardQuicksight />
        </div>
    );
};

export default DashboardsDefault;
