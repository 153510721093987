// material-ui
import {Box, Button, FormHelperText, Grid, InputLabel, Menu, Modal, OutlinedInput, Stack} from "@mui/material";

// project import
import ConnectionsTable from "./ConnectionsTable";
import MainCard from "components/MainCard";
import AnimateButton from "../../components/@extended/AnimateButton";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import React, {useState, useEffect} from "react";
import Loader from "../../components/Loader";
import {
    useAddQuicksightConnectionMutation,
    useGetMeQuery, useUpdateQuicksightConnectionRefreshMutation,
    useGetQuicksightConnectionsQuery,
    useUpdateQuicksightDashboardRefreshMutation
} from "../../store/api";
import {ROLE_URL} from 'config';

// ==============================|| USERS - DEFAULT ||============================== //

const ConnectionsDefault = () => {
    const [open, setOpen] = useState(false);
    const [serverError, setServerError] = useState(null);

    const [addConnection, { isLoading }] = useAddQuicksightConnectionMutation();
    const [connectionDetails, setConnectionDetails] = useState(null);
    const [refreshQuicksightDashboards, { isRefreshLoading: isRefreshUpdating }] = useUpdateQuicksightDashboardRefreshMutation();
    const [refreshQuicksightConnections, { isRefreshLoading: isRefreshConnectionUpdating }] = useUpdateQuicksightConnectionRefreshMutation();

    const { data: rows, isLoading: isConnectionsLoading, error: isConnectionsError } = useGetQuicksightConnectionsQuery();


    const handleOpen = () => {
        setConnectionDetails(null);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const regions = [
        // {name: 'Africa (Cape Town), af-south-1', id: 'af-south-1'},

        // {name: 'Asia Pacific (Hong Kong), ap-east-1', id: 'ap-east-1'},
        // {name: 'Asia Pacific (Hyderabad), ap-south-2', id: 'ap-south-2'},
        // {name: 'Asia Pacific (Jakarta), ap-southeast-3', id: 'ap-southeast-3'},
        // {name: 'Asia Pacific (Melbourne), ap-southeast-4', id: 'ap-southeast-4'},
        {name: 'Asia Pacific (Mumbai), ap-south-1', id: 'ap-south-1'},
        // {name: 'Asia Pacific (Osaka), ap-northeast-3', id: 'ap-northeast-3'},
        {name: 'Asia Pacific (Seoul), ap-northeast-2', id: 'ap-northeast-2'},
        {name: 'Asia Pacific (Singapore), ap-southeast-1', id: 'ap-southeast-1'},
        {name: 'Asia Pacific (Sydney), ap-southeast-2', id: 'ap-southeast-2'},
        {name: 'Asia Pacific (Tokyo), ap-northeast-1', id: 'ap-northeast-1'},

        {name: 'Canada (Central), ca-central-1', id: 'ca-central-1'},

        {name: 'Europe (Frankfurt), eu-central-1', id: 'eu-central-1'},
        {name: 'Europe (Ireland), eu-west-1', id: 'eu-west-1'},
        {name: 'Europe (London), eu-west-2', id: 'eu-west-2'},
        // {name: 'Europe (Milan), eu-south-1', id: 'eu-south-1'},
        // {name: 'Europe (Paris), eu-west-3', id: 'eu-west-3'},
        // {name: 'Europe (Spain), eu-south-2', id: 'eu-south-2'},
        {name: 'Europe (Stockholm), eu-north-1', id: 'eu-north-1'},
        // {name: 'Europe (Zurich), eu-central-2', id: 'eu-central-2'},

        // {name: 'Israel (Tel Aviv), il-central-1', id: 'il-central-1'},

        // {name: 'Middle East (Baharian), me-south-1', id: 'me-south-1'},
        // {name: 'Middle East (UAE), me-central-1', id: 'me-central-1'},

        {name: 'South America (Sao Paulo), sa-east-1', id: 'sa-east-1'},

        {name: 'US East (N. Virginia), us-east-1', id: 'us-east-1'},
        {name: 'US East (Ohio), us-east-2', id: 'us-east-2'},
        // {name: 'US West (N. California), us-west-1', id: 'us-west-1'},
        {name: 'US West (Oregon), us-west-2', id: 'us-west-2'},

    ];

    // Using a query hook automatically fetches data and returns query values
    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 3 */}
            <Grid item>
                <div>
                    <span style={{ float: 'left', paddingRight: '10px' }}>
                        <AnimateButton>
                            <Button
                                disableElevation
                                disabled={false}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleOpen}
                            >
                                Add Connection
                            </Button>
                        </AnimateButton>
                    </span>
                    <span style={{ float: 'right', paddingLeft: '10px' }}>
                        <AnimateButton>
                            {!isRefreshConnectionUpdating ? (
                                <Button
                                    disableElevation
                                    disabled={false}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                    onClick={refreshQuicksightConnections}
                                >
                                    Refresh Connections
                                </Button>
                            ) : (
                                <Loader />
                            )}
                        </AnimateButton>
                    </span>
                </div>


                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            pt: 2.25,
                            backgroundColor: "white",
                            width: "90%",
                            margin: "0 auto",
                            marginTop: "100px",
                            padding: "20px",
                            maxWidth: "600px"
                        }}
                    >
                        <Formik
                            initialValues={{
                                name: "",
                                description: "",
                                region_name: ""
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().min(4).max(255).required("Name is required"),
                                description: Yup.string().max(255),
                                region_name: Yup.string().max(255).required("Region is required"),
                            })}
                            onSubmit={async (data) => {
                                //console.log(data);

                                try {
                                    let newConnection = await addConnection(data).unwrap();
                                    setConnectionDetails(newConnection);
                                    refreshQuicksightDashboards(newConnection.id)
                                }
                                catch (e) {
                                    setServerError(e.data.detail)
                                }
                                return true;
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="name-connection">Name</InputLabel>
                                                <OutlinedInput
                                                    id="name-connection"
                                                    type="name"
                                                    value={values.name}
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={connectionDetails}
                                                    placeholder="Name"
                                                    fullWidth
                                                    error={Boolean(touched.name && errors.name)}
                                                />
                                                {touched.name && errors.name && (
                                                    <FormHelperText error id="standard-weight-helper-text-name-dashboard">
                                                        {errors.name}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="description-connection">Description</InputLabel>
                                                <OutlinedInput
                                                    id="description-connection"
                                                    type="description"
                                                    value={values.description}
                                                    name="description"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={connectionDetails}
                                                    placeholder="Description"
                                                    fullWidth
                                                    error={Boolean(touched.description && errors.description)}
                                                />
                                                {touched.description && errors.description && (
                                                    <FormHelperText error id="standard-weight-helper-text-account-id-connection">
                                                        {errors.description}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel htmlFor="iam-role-connection">Region</InputLabel>
                                                <Field
                                                    component={"select"}
                                                    style={{
                                                        height: '40px',
                                                        paddingLeft: '10px',
                                                        fontFamily: 'Public Sans, sans-serif',
                                                        backgroundColor: '#ffffff',
                                                        border: '1.5px solid #d9d9d9',
                                                        borderRadius: '5px',
                                                    }}

                                                    id="region_name"
                                                    type="region_name"
                                                    value={values.region}
                                                    name="region_name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    disabled={connectionDetails}

                                                >
                                                    {regions && regions.map(region =>
                                                        <option value={region.id} style={{fontFamily: 'Public Sans, sans-serif'}}>
                                                            {region.name}
                                                        </option>
                                                    )}

                                                </Field>

                                            </Stack>
                                        </Grid>
                                        {errors.submit && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>Errors - {errors.submit}</FormHelperText>
                                            </Grid>
                                        )}
                                        {serverError && (
                                            <Grid item xs={12}>
                                                <FormHelperText error>Error: {serverError}</FormHelperText>
                                            </Grid>
                                        )}
                                        {!connectionDetails && (
                                        <Grid item xs={12}>
                                            <AnimateButton>
                                                {!isLoading ? (
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting || connectionDetails}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Create Connection
                                                    </Button>
                                                ) : (
                                                    <Loader />
                                                )}
                                            </AnimateButton>
                                        </Grid>
                                        )}
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                        </Box>
                </Modal>
            </Grid>
            <Grid item />
            <Grid item xs={12} md={12} lg={12}>
                <MainCard sx={{ mt: 2 }} content={false}>
                    <ConnectionsTable />
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default ConnectionsDefault;
