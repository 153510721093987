import { Link } from 'react-router-dom';

// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import AuthForgotUser from './auth-forms/AuthForgotUser';
import AuthWrapper from './AuthWrapper';

// ================================|| LOGIN ||================================ //

const ForgotPasswordUser = () => (
    <AuthWrapper>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h2>Forgot your password?</h2>
                <p>Input your email in the box below, and we'll send you an email with your temporary login details</p>
            </Grid>
            <Grid item xs={12}>
                <AuthForgotUser />
            </Grid>
        </Grid>
    </AuthWrapper>
);

export default ForgotPasswordUser;
