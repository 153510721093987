import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DashboardTable from "./DashboardsTable";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import getBranding from "../../../store/services/get-branding";
import {LockTwoTone} from "@mui/icons-material";
import {useCallback, useEffect, useState} from "react";
import {useGetMeQuery, useUpdateUserMutation, useUpdateUserPasswordMutation} from "../../../store/api";

export default function DashboardDrawer() {
    const branding = getBranding();

    const [state, setState] = React.useState({});
    const domainToRedirect = JSON.parse(sessionStorage.getItem('brand'))['domain_name'];

    function removeData() {
        sessionStorage.removeItem('user')
        sessionStorage.removeItem('access_token');
        setTimeout(() => window.location.replace("/"), 200);

    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };


    // get current user
    let {data: Me} = useGetMeQuery();
    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (Me) {
            setCompanyId(Me.company_id);
        }
    }, [Me])

    const [userName, setUserName] = useState('')
    const [userData, setUserData] = useState({id: '', company_id: '', password: '', passwordConfirm: ''})
    useEffect(() => {
        if (Me) {
            setUserData({
                id: Me.id,
                company_id: companyId || Me.company_id,
                password: null,
                passwordConfirm: null,
            });
            setUserName(Me.name);
        }
    }, [Me, companyId])

    const [openPassword, setOpenPassword] = useState(false);

    const [updateUser, {isLoading}] = useUpdateUserPasswordMutation();

    const onPasswordUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, password: dataToUse.target.value}))
    }, []);
    const onPasswordConfirmUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, passwordConfirm: dataToUse.target.value}))
    }, []);

    const handlePasswordOpen = () => { setOpenPassword(true); setPasswordError(null); };

    const [passwordError, setPasswordError] = useState("")
    const handlePasswordSave = () => {
        if (userData.password === userData.passwordConfirm && Me) {
            console.log("Data: ", userData);
            updateUser({id: Me.id, user_data: userData});
            setOpenPassword(false);
        } else {
            userData.passwordConfirm = null;
            setPasswordError("Passwords do not match");
        }
    }
    const handlePasswordClose = () => { setOpenPassword(false); };


    const list = (anchor) => (
        <Box
            sx={{ width: 250, backgroundColor: 'transparent' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <div style={{ position: 'fixed', top: 0 }}>
                    <p style={{
                        fontSize: 25,
                        marginTop: 0,
                        marginBottom: 0,
                        width: 250,
                        height: 65,
                        paddingLeft: '12%',
                        paddingTop: '5%',
                        backgroundColor: branding.primary_colour,
                        color: branding.secondary_colour
                    }}>
                        Dashboards
                    </p>
                </div>

                <div style={{ marginTop: 57, marginBottom: 120 }}>
                    <DashboardTable/>
                </div>

                <div style={{
                    backgroundColor: branding.primary_colour,
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    paddingBottom: 5,
                    paddingRight: 50
                }}>

                    <div style={{
                        paddingLeft: '35px',
                        color: branding.secondary_colour,
                        fontWeight: "bold",
                        marginTop: '20px'
                    }}>
                        {userName}
                    </div>
                    <br/>

                    <Button size="large" onClick={handlePasswordOpen}
                            style={{marginLeft: '15px', color: branding.secondary_colour}}>
                        <LockTwoTone/>
                        <div style={{paddingLeft: '5px'}}> Change Password</div>
                    </Button>
                    <br/>

                    <Button size="large" onClick={removeData}
                            style={{marginLeft: '15px', color: branding.secondary_colour}}>
                        <LogoutTwoToneIcon/>
                        <div style={{paddingLeft: '5px'}}> Logout</div>
                    </Button>
                </div>

            </List>
        </Box>
    );

    return (
        <div>

            {/* Dialog for Password Start */}
            <Dialog open={openPassword} onClose={handlePasswordClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Input your new password:
                    </DialogContentText>
                    <TextField type={'password'} onChange={onPasswordUpdate}/>
                </DialogContent>

                <DialogContent>
                    <DialogContentText>
                        Confirm your new password:
                    </DialogContentText>
                    <TextField type={'password'} onChange={onPasswordConfirmUpdate} />
                </DialogContent>

                {passwordError ?
                    <DialogContent>
                        <DialogContentText> Passwords do not match </DialogContentText>
                    </DialogContent>
                    :
                    <></>
                }

                <DialogActions>
                    <Button onClick={handlePasswordClose}>Cancel</Button>
                    <Button onClick={handlePasswordSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Password Close */}


            {['DropDown'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)} style={{height: '65px'}}>
                        <IconButton
                            edge="start"
                            color="secondary"
                            sx={{ color: branding.secondary_colour}}
                        >
                            <MenuOutlinedIcon sx={{fontSize: 45}} />
                        </IconButton>
                    </Button>
                    <Drawer
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
