import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { userLogin} from '../../../store/reducers/auth';
import { clearMessage } from '../../../store/reducers/message';



import {useGetCompanyBrandingQuery} from "../../../store/clientApi";

// material-ui
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import
import AnimateButton from 'components/@extended/AnimateButton';

// assets
const AuthLogin = () => {
    const [checked, setChecked] = React.useState(false);
    const { isLoggedIn } = useSelector((state) => state.auth);

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const domain = window.location.host;
    const pathname = window.location.pathname;
    const {data: branding, error, isLoading} = useGetCompanyBrandingQuery({domain});
    const [company_id, setCompanyId] = React.useState(null);

    useEffect(() => {
        dispatch(clearMessage());
    }, [dispatch]);

    useEffect(() => {
        setCompanyId(branding.id);
    }, [branding]);

    useEffect(() => {
        if (isLoggedIn && !pathname.includes('admin')) {
            navigate("/dashboard", { replace: true });
        }
    },[isLoggedIn])
    const handleLogin = (values, { setErrors, setStatus, setSubmitting }) => {
        const { email, password } = values;
        setSubmitting(true);
        dispatch(userLogin({ email, password, company_id }))
            .unwrap()
            .then((returned) => {
                if (returned) {
                    setStatus({ success: true });
                    setSubmitting(false);
                    navigate("/dashboard");
                }
            })
            .catch((err) => {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            });
    };


    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Must be a valid email").min(8).max(255).required("Email is required"),
                    password: Yup.string().max(255).required("Password is required")
                })}
                onSubmit={handleLogin}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login">Email Address</InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="Enter email address"
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-login">Password</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={Boolean(touched.password && errors.password)}
                                        id="-password-login"
                                        type={showPassword ? "text" : "password"}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="Enter password"
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: -1 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) => setChecked(event.target.checked)}
                                                name="checked"
                                                color="primary"
                                                size="small"
                                            />
                                        }
                                        label={<Typography variant="h6">Remember Me</Typography>}
                                    />
                                    <Link variant="h6" component={RouterLink} to="/forgot-password" color="text.primary">
                                        Forgot Password?
                                    </Link>
                                </Stack>
                            </Grid>
                            {errors.submit && (
                                <Grid item xs={12}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Login
                                    </Button>
                                </AnimateButton>
                            </Grid>

                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};


export default AuthLogin;
