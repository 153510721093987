// ==============================|| OVERRIDES - BUTTON ||============================== //

import {useLocation} from "react-router-dom";

export default function Button(theme) {

    const params = useLocation();

    if (params.pathname.toString().includes('/admin')){
        const disabledStyle = {
            '&.Mui-disabled': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.disabled,
                boxShadow: 'none'
            }
        };

        const hoverAndActiveStyle = {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#ffffff',
            },
        };

        return {
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                        borderRadius: 8,
                        textTransform: 'none',
                        fontSize: '0.875rem',
                        letterSpacing: '0.02857em',
                        padding: '6px 16px',
                        border: '1px solid #ffffff',
                        transition: 'background-color 250ms ease, box-shadow 250ms ease',
                    },
                    contained: {
                        ...disabledStyle,
                        ...hoverAndActiveStyle
                    },
                    outlined: {
                        ...disabledStyle,
                        ...hoverAndActiveStyle,
                        borderColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        }
                    },
                    text: {
                        ...hoverAndActiveStyle
                    }
                }
            }
        };
    }
    else {
        const disabledStyle = {
            '&.Mui-disabled': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.text.disabled,
                boxShadow: 'none'
            }
        };

        const hoverAndActiveStyle = {
            '&:hover': {

            },
        };

        return {
            MuiButton: {
                defaultProps: {
                    disableElevation: true
                },
                styleOverrides: {
                    root: {
                        fontWeight: 600,
                        borderRadius: 8,
                        textTransform: 'none',
                        fontSize: '0.875rem',
                        letterSpacing: '0.02857em',
                        padding: '6px 16px',
                        transition: 'background-color 250ms ease, box-shadow 250ms ease',
                    },
                    contained: {
                        ...disabledStyle,
                        ...hoverAndActiveStyle
                    },
                    outlined: {
                        ...disabledStyle,
                        ...hoverAndActiveStyle,
                        borderColor: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        }
                    },
                    text: {
                        ...hoverAndActiveStyle
                    }
                }
            }
        };
    }
}
