import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Link, List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {useNavigate} from 'react-router-dom';
// assets
import {
    LeftSquareTwoTone,

    SettingTwoTone,
} from '@ant-design/icons';
import {useGetCompanyQuery, useGetMeQuery} from "../../../../../store/api";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    function removeData() {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('access_token');
        setTimeout(() => window.location.replace('/admin/login'), 200);
    }

    // get current user company id
    let {data: Me} = useGetMeQuery();
    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (Me) { setCompanyId(Me.company_id); }
    }, [Me])

    // get current company data
    const {data: myCompanyData} = useGetCompanyQuery(companyId, {skip : !companyId});

    const [companyData, setCompanyData] = useState({name: ''});
    useEffect(() => {
        if (myCompanyData) {
            setCompanyData({
                name: myCompanyData.name.toString(),
            });
        }
    }, [myCompanyData])

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton onClick={() => navigate('/admin/account-settings')}>
                <ListItemIcon>
                    <SettingTwoTone twoToneColor={"#efc402"} />
                </ListItemIcon>
                <ListItemText primary="Account Settings" />
            </ListItemButton>
            <ListItemButton onClick={removeData}>
                <ListItemIcon>
                    <LeftSquareTwoTone twoToneColor={"#efc402"} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
