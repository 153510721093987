import { cakeAPI } from "../api";
import { cakeClientApi } from "../clientApi";

const api = {
    // Add the generated reducer as a specific top-level slice
    [cakeAPI.reducerPath]: cakeAPI.reducer,
    [cakeClientApi.reducerPath]: cakeClientApi.reducer
};

export default api;
