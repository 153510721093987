import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import TermsOfService from "../pages/extra-pages/TermsOfService";
import PrivacyPolicy from "../pages/extra-pages/PrivacyPolicy";
import ForgotPasswordAdmin from "../pages/authentication/ForgotPasswordAdmin";
import ClientLogin from "../pages/authentication/ClientLogin";
// ==============================|| AUTH ROUTING ||============================== //

const MainRoute = {
    path: '/',
    element: <ClientLogin />
};

export default MainRoute;
