// material-ui
import {
    Avatar,
    Button,
    Grid,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,

} from "@mui/material";
import TextField from '@mui/material/TextField';
import {useCallback, useEffect, useState} from 'react';

// project import
import MainCard from "components/MainCard";
import {CloseSquareTwoTone, LockTwoTone, MailTwoTone, SmileTwoTone,} from "@ant-design/icons";
import {
    useDeleteUserMutation, useGetMeQuery,
    useGetUserQuery,
    useUpdateUserMutation
} from "../../store/api";
import {useParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

// ==============================|| USERS - DEFAULT ||============================== //

const UserAccountSettingsDefault = () => {
    const theme = useTheme();

    const {data: Me} = useGetMeQuery();
    const {data: user, error, isLoading: isUserLoading } = useGetUserQuery(useParams().id);

    const [companyId, setCompanyId] = useState(null);
    useEffect(() => {
        if (user) { setCompanyId(user.company_id); }
    }, [user])

    const [userData, setUserData] = useState({id: '', name: '', email: '', role: '', company_id: '', password: ''})
    useEffect(() => {
        if (user) {
            setUserData({
                id: user.id ? user.id.toString() : "", //static
                name: user.name ? user.name.toString() : "",
                email: user.email ? user.email.toString() : "",
                role: user.role, //static
                company_id: companyId, //static
                password: null,
            });
        }
    }, [user, companyId])

    const [allowDelete, setAllowDelete] = useState(false);
    useEffect(() => {
        if (user && Me) {
            if (Me.id !== user.id) { setAllowDelete(true); }
        }
    }, [user])

    // set up open[value] vars here
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    // update user data
    const [updateUser, {isLoading}] = useUpdateUserMutation();

    const onNameUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, name: dataToUse.target.value}))
    }, []);
    const onEmailUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, email: dataToUse.target.value}))
    }, []);
    const onPasswordUpdate = useCallback((dataToUse) => {
        setUserData(prevState => ({...prevState, password: dataToUse.target.value}))
    }, []);

    // open pop-up windows
    const handleNameOpen = () => { setOpenName(true); };
    const handleEmailOpen = () => { setOpenEmail(true); };
    const handlePasswordOpen = () => { setOpenPassword(true); };
    const handleDeleteOpen = () => { setOpenDelete(true); };

    // save
    const handleNameSave = () => {
        updateUser({ id: user.id, user_data: userData });
        setOpenName(false); };
    const handleEmailSave = () => {
        updateUser({ id: user.id, user_data: userData });
        setOpenEmail(false); };
    const handlePasswordSave = () => {
        console.log("Data: ", userData);
        updateUser({ id: user.id, user_data: userData });
        setOpenPassword(false); };

    const [deleteUser, {isDeleting}] = useDeleteUserMutation();
    const handleDeleteSave = () => {
        deleteUser({userId: userData.id});
        setOpenDelete(false); };

    // close pop-up windows
    const handleNameClose = () => { setOpenName(false); };
    const handleEmailClose = () => { setOpenEmail(false); };
    const handlePasswordClose = () => { setOpenPassword(false); };
    const handleDeleteClose = () => { setOpenDelete(false); };


    return (
        <>
        {userData ?
        <div>

            {/* Dialog for Name Start */}
            <Dialog open={openName} onClose={handleNameClose}>
                <DialogTitle>Update Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your name here:
                    </DialogContentText>
                    <TextField defaultValue={userData.name} onChange={onNameUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNameClose}>Cancel</Button>
                    <Button onClick={handleNameSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Name Close */}

            {/* Dialog for Email Start */}
            <Dialog open={openEmail} onClose={handleEmailClose}>
                <DialogTitle>Update Email</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your email here:
                    </DialogContentText>
                    <TextField defaultValue={userData.email} onChange={onEmailUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEmailClose}>Cancel</Button>
                    <Button onClick={handleEmailSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Email Close */}

            {/* Dialog for Password Start */}
            <Dialog open={openPassword} onClose={handlePasswordClose}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change your password here:
                    </DialogContentText>
                    <TextField onChange={onPasswordUpdate} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordClose}>Cancel</Button>
                    <Button onClick={handlePasswordSave}>Save</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Password Close */}

            {/* Dialog for Delete Start */}
            <Dialog open={openDelete} onClose={handleDeleteClose}>
                <DialogTitle>Delete User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this user?
                    </DialogContentText>
                    <DialogContentText>
                        This action cannot be undone
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>Cancel</Button>
                    <Button onClick={handleDeleteSave} style={{color: "#ea0909", backgroundColor: "#ffffff", border: "1px solid #ea0909"}}>Delete</Button>
                </DialogActions>
            </Dialog>
            {/* Dialog for Delete Close */}


            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                {/* row 3 */}
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard sx={{ mt: 2 }} content={false}>
                        <List component="nav">

                            <ListItemButton divider onClick={handleNameOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: '#f2f2f2'
                                        }}
                                    >
                                        <SmileTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Update Name</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {userData.name}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            <ListItemButton divider onClick={handleEmailOpen}>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            bgcolor: '#f2f2f2'
                                        }}
                                    >
                                        <MailTwoTone twoToneColor={theme.palette.primary.main} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="subtitle1">Update Email</Typography>} />
                                <ListItemSecondaryAction>
                                    <Stack alignItems="flex-end">
                                        <Typography variant="subtitle1" noWrap>
                                            {userData.email}
                                        </Typography>
                                    </Stack>
                                </ListItemSecondaryAction>
                            </ListItemButton>

                            {allowDelete ?
                                <>
                                    <ListItemButton divider onClick={handlePasswordOpen}>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    bgcolor: '#f2f2f2'
                                                }}
                                            >
                                                <LockTwoTone twoToneColor={theme.palette.primary.main} />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Typography variant="subtitle1">Update Password</Typography>} />
                                        <ListItemSecondaryAction>
                                            <Stack alignItems="flex-end">
                                                <Typography variant="subtitle1" noWrap />
                                            </Stack>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>

                                    <ListItemButton onClick={handleDeleteOpen}>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    bgcolor: '#f2f2f2'
                                                }}
                                            >
                                                <CloseSquareTwoTone twoToneColor={"#ea0909"}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Typography variant="subtitle1">Delete User</Typography>} />
                                        <ListItemSecondaryAction>
                                            <Stack alignItems="flex-end">
                                                <Typography variant="subtitle1" noWrap />
                                            </Stack>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                </>
                            :
                                <ListItemButton onClick={handlePasswordOpen}>
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                bgcolor: '#f2f2f2'
                                            }}
                                        >
                                            <LockTwoTone twoToneColor={theme.palette.primary.main} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={<Typography variant="subtitle1">Update Password</Typography>} />
                                    <ListItemSecondaryAction>
                                        <Stack alignItems="flex-end">
                                            <Typography variant="subtitle1" noWrap />
                                        </Stack>
                                    </ListItemSecondaryAction>
                                </ListItemButton>
                            }

                        </List>
                    </MainCard>
                </Grid>
            </Grid>
        </div> : <div></div> }
        </>
    );
};

export default UserAccountSettingsDefault;
