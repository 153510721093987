import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessage } from './message';

import AuthService from '../services/auth.service';

const user = JSON.parse(sessionStorage.getItem('user'));

export const register = createAsyncThunk('auth/register', async ({ name, email, password, passwordConfirm, company, hostname }, thunkAPI) => {
    try {
        const response = await AuthService.register(name, email, password, passwordConfirm, company, hostname);
        thunkAPI.dispatch(setMessage(response.data.message));
        return response.data;
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const userLogin = createAsyncThunk('auth/login', async ({ email, password, company_id }, thunkAPI) => {
    try {
        const data = await AuthService.userLogin(email, password, company_id);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const adminLogin = createAsyncThunk('auth/login', async ({ email, password }, thunkAPI) => {
    try {
        const data = await AuthService.adminLogin(email, password);
        return { user: data };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout();
});

export const forgot_admin = createAsyncThunk('auth/login', async ({ email, hostname }, thunkAPI) => {
    try {
        const data = await AuthService.forgotAdmin(email, hostname);
        return { user: data };
    } catch (error) {
        console.log("Error: ", JSON.stringify(error));
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const forgot_user = createAsyncThunk('auth/login', async ({ email, hostname }, thunkAPI) => {
    try {
        const data = await AuthService.forgotUser(email, hostname);
        return { user: data };
    } catch (error) {
        console.log("Error: ", JSON.stringify(error));
        const message = (error.response && error.response.data && error.response.data.detail) || error.message || error.toString();
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue(message);
    }
});

export const token = createAsyncThunk('auth/logout', async () => {
    //debugger
});

const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [register.fulfilled]: (state) => {
            state.isLoggedIn = false;
        },
        [register.rejected]: (state) => {
            state.isLoggedIn = false;
        },
        [adminLogin.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [adminLogin.rejected]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [userLogin.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        [userLogin.rejected]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        /*[token.fulfilled]: (state, action) => {
            debugger

            if (action.type === 'auth/logout/fulfilled') {
                state.isLoggedIn = false;
                state.user = null;
            }

            state.token = action.payload.token;
            state.isLoggedIn = true;
        }*/
    }
});

const { reducer } = authSlice;
export default reducer;
