const Typography = (fontFamily) => ({
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        fontSize: '2.375rem',
        lineHeight: 1.2,
        letterSpacing: '-0.01562em'
    },
    h2: {
        fontWeight: 600,
        fontSize: '1.875rem',
        lineHeight: 1.25,
        letterSpacing: '-0.00833em'
    },
    h3: {
        fontWeight: 600,
        fontSize: '1.5rem',
        lineHeight: 1.3,
        letterSpacing: '0em'
    },
    h4: {
        fontWeight: 600,
        fontSize: '1.25rem',
        lineHeight: 1.35,
        letterSpacing: '0.00735em'
    },
    h5: {
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: 1.4,
        letterSpacing: '0em'
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.6,
        letterSpacing: '0.0075em'
    },
    caption: {
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: 1.6,
        letterSpacing: '0.03333em'
    },
    body1: {
        fontSize: '1rem',
        lineHeight: 1.5,
        letterSpacing: '0.00938em'
    },
    body2: {
        fontSize: '0.875rem',
        lineHeight: 1.57,
        letterSpacing: '0.01071em'
    },
    subtitle1: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: '0.00938em'
    },
    subtitle2: {
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: 1.57,
        letterSpacing: '0.00714em'
    },
    overline: {
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.08333em',
        textTransform: 'uppercase'
    },
    button: {
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing: '0.02857em'
    }
});

export default Typography;
