import PropTypes from 'prop-types';
import {useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';


// material-ui
import {Link, Stack, Table, TableBody, TableCell, TableRow, Typography} from '@mui/material';

// third-party

// project import
import Dot from 'components/@extended/Dot';
import {useGetUserDashboardDataQuery} from "../../../store/api";
import getBranding from "../../../store/services/get-branding";
import * as React from "react";

// ==============================|| USER TABLE - STATUS ||============================== //

const Dashboardstatus = ({status}) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Disabled';
            break;
        case 1:
            color = 'success';
            title = 'Active';
            break;
        case 2:
            color = 'success';
            title = 'Active';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (<Stack direction="row" spacing={1} alignItems="center">
        <Dot color={color}/>
        <Typography>{title}</Typography>
    </Stack>);
};

Dashboardstatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| USER TABLE ||============================== //

export default function DashboardTable() {
    const branding = getBranding();
    const params = useParams();
    console.log("Params: ", params);
    let {data: dashboards, error, isLoading} = useGetUserDashboardDataQuery();
    const navigate = useNavigate();

    // for (let i=0; i<10; i++){
    //     dashboards = dashboards.concat(dashboards[0]);
    // }

    return (
        <>
            <Table
                aria-labelledby="tableTitle"
                sx={{
                    '& .MuiTableCell-root:first-child': {pl: 3},
                    '& .MuiTableCell-root:last-child': {pr: 5}
                }}
            >
                {dashboards && (<TableBody>
                    {dashboards.map((dashboard, index) => {
                        const isItemSelected = (params.dashboardId == dashboard.id);
                        return (<TableRow
                            hover
                            role="checkbox"
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={dashboard.id}
                            selected={isItemSelected}
                        >
                            <TableCell align="left">
                                <Link color="secondary" component={RouterLink} to={''} onClick={(e) => {
                                    navigate("/connection/" + dashboard.connection_id + "/dashboard/" + dashboard.id);
                                    e.preventDefault();
                                }}>
                                    <div style={{ overflowX: 'hidden' }}>
                                        {isItemSelected ?
                                            <span style={{ paddingLeft: '8px', fontSize: 18, paddingTop: '0px', fontWeight:'bold', color: '#219fd7' }}>
                                                {dashboard.name}
                                            </span>
                                        :
                                            <span style={{ paddingLeft: '8px', fontSize: 18, paddingTop: '5px', fontWeight:'bold' }}>
                                                {dashboard.name}
                                            </span>
                                        }
                                    </div>

                                </Link>
                            </TableCell>
                        </TableRow>);
                    })}
                </TableBody>)}
            </Table>
        </>
    );
}
