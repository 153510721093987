import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Dashboard from "../pages/dashboard/user-dashboard";
import ForgotPasswordUser from "../pages/authentication/ForgotPasswordUser";
import ForgotPasswordAdmin from "../pages/authentication/ForgotPasswordAdmin";
import TermsOfService from "../pages/extra-pages/TermsOfService";
import PrivacyPolicy from "../pages/extra-pages/PrivacyPolicy";

// render - login
const ClientLogin = Loadable(lazy(() => import("pages/authentication/ClientLogin")));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthRegistered = Loadable(lazy(() => import('pages/authentication/Registered')));


// ==============================|| CLIENT ROUTING ||============================== //

const ClientRoutes = {
    path: "/",
    element: <MinimalLayout />,
    children: [
        {
            path: "/dashboard/:id",
            element: <Dashboard />
        },
        {
            path: "/connection/:connectionId/dashboard/:dashboardId",
            element: <Dashboard />
        },
        {
            path: "/dashboard",
            element: <Dashboard />
        },
        {
            path: '/forgot-password',
            element: <ForgotPasswordUser />
        },
        {
            path: '/admin/login',
            element: <AuthLogin />
        },
        {
            path: '/admin/register',
            element: <AuthRegister />
        },
        {
            path: '/admin/registered',
            element: <AuthRegistered />
        },
        {
            path: '/admin/forgot-password',
            element: <ForgotPasswordAdmin />
        },
        {
            path: '/admin/tos',
            element: <TermsOfService />
        },
        {
            path: '/admin/privacy',
            element: <PrivacyPolicy />
        },
        {
            path: '/:domain',
            element: <ClientLogin />
        }
    ]
};

export default ClientRoutes;
