import {useCallback, useEffect, useRef, useState} from 'react';

import {useGetUserDashboardDataQuery, useLazyGetQuicksightUrlQuery} from "../../../store/api";
import getBranding from "../../../store/services/get-branding";
import {useLocation, useNavigate} from 'react-router';
import {useParams} from "react-router-dom";
import {createEmbeddingContext} from 'amazon-quicksight-embedding-sdk';
import UserHome from "../../extra-pages/UserHome";


// ==============================|| USERS - DEFAULT ||============================== //


const DashboardQuicksight = (props) => {
    const branding = getBranding();
    const navigate = useNavigate();
    let location = useLocation();
    const quicksightContainer = useRef();

    const {data: rows, error} = useGetUserDashboardDataQuery();
    let {connectionId, dashboardId} = useParams();
    const [showUserHome, setShowUserHome] = useState(false);
    const [getDashboard, {data, isLoading: isQuicksightPageLoading, qserror}] = useLazyGetQuicksightUrlQuery()
    const [embedUrl, setEmbedUrl] = useState(null);
    const [content, setContent] = useState(<></>)

    useEffect(() => {
        // console.log("Location: ", location);
        if (dashboardId && dashboardId !== "") {
            setShowUserHome(false);
            getDashboard({dashboardId, connectionId})
                .unwrap()
                .then((fulfilled) => {
                    // console.log("Fulfilled: ", fulfilled);
                    setEmbedUrl(fulfilled.embed_url);
                })
                .catch((rejected) => console.log("Rejected: ", rejected))
            // setEmbedUrl(dashboardResponse.embed_url);
        } else if (rows && rows.length > 0) {
            setShowUserHome(false);
            navigate("/connection/" + rows[0].connection_id + "/dashboard/" + rows[0].id);
        } else {
            console.log("Show User Home");
            setShowUserHome(true);
        }

    }, [location, rows]);


    const loadDashboard = useCallback(async () => {
        if (embedUrl !== null && quicksightContainer && quicksightContainer.current) {


            quicksightContainer.current.replaceChildren();


            const embeddingContext = await createEmbeddingContext({
                onChange: (changeEvent) => {
                    // console.log('Context received a change', changeEvent);
                },
            });

            const frameOptions = {
                url: embedUrl,
                container: document.getElementById(quicksightContainer.current.id),
                width: "100%",
                height: "97%",
                withIframePlaceholder: true,
                resizeHeightOnSizeChangedEvent: true,
            };

            const contentOptions = {
                sheetOptions: {
                    singleSheet: false
                }
            };

            await embeddingContext.embedDashboard(frameOptions, contentOptions);
        }
    }, [embedUrl]);

    useEffect(() => {
        if (rows && rows.length > 0) {
            setContent(<div
                ref={quicksightContainer}
                title={"Quicksight Dashboard"}
                id={"quicksight-dashboard"}
                style={{backgroundColor: branding.primary_colour, height: "100%"}}
            />)
            loadDashboard().then(r => console.log("Loading Dashboard"));
        } else if (!isQuicksightPageLoading && showUserHome) {
            setContent(<UserHome/>);
        } else {
            setContent(<></>);
        }
    }, [embedUrl, loadDashboard, rows])


    return (
        <div style={{
            flex: 1,
            alignItems: "stretch",
            backgroundColor: branding.primary_colour,
            padding: 0,
            height: "93vh"
        }}>
            {content}
        </div>

    );
};

export default DashboardQuicksight;
