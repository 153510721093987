// material-ui
import {Grid, Stack, Typography} from '@mui/material';

// project import
import AuthWrapper from './AuthWrapper';
import ClientAuthLogin from './auth-forms/ClientAuthLogin';
import {useGetCompanyBrandingQuery} from '../../store/clientApi';
import {useNavigate, useParams} from 'react-router-dom';
import NoDomain from "../extra-pages/NoDomain";
import Loader from "../../components/Loader";
import {useSelector} from "react-redux";
import {useEffect} from "react";

// ================================|| LOGIN ||================================ //

const Login = () => {
    const domain = window.location.host;
    const pathname = window.location.pathname;
    const navigate = useNavigate();
    const {data: branding, isLoading: brandingLoading} = useGetCompanyBrandingQuery({domain});
    console.log("Branding: ", branding);
    const { isLoggedIn } = useSelector((state) => state.auth);
    useEffect(() => {
        if (isLoggedIn && !pathname.includes('admin')) {
            navigate('/dashboard');
        }
    }, [isLoggedIn]);


    return (
        <>
            {brandingLoading ?
                <Loader/>
                :
                <>
                {branding ? <div style={{maxHeight: window.outerHeight, backgroundColor: branding.primary_colour || "#ccc"}}>
                        <AuthWrapper>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    {branding && branding.logo_path && (
                                        <img alt={""} src={branding.logo_path}
                                             style={{maxWidth: "20%", margin: "auto"}}/>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="baseline"
                                           sx={{mb: {xs: -0.5, sm: 0.5}}}>
                                        <Stack direction={"column"} spacing={3}>
                                            <Typography variant="h3">Login</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <ClientAuthLogin/>
                                </Grid>
                            </Grid>
                        </AuthWrapper>
                    </div> : <NoDomain />}
                </>


            }

        </>
    );
};

export default Login;
