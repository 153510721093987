import * as React from "react";

const NoDomain = () => (
    <html lang="en" style={{boxSizing: 'border-box'}}>
        <head>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {/*The above 3 meta tags *must* come first in the head; any other head content must come *after* these tags*/}
            <title>No Domain 404</title>
            {/*Google font*/}
            <link href="https://fonts.googleapis.com/css?family=Maven+Pro:400,900" rel="stylesheet" />
        </head>
        <body style={{padding: 0, margin: 0}}>
            <div id="notfound" style={{position: 'relative', height: '100vh'}}>
                <div className="notfound" style={{
                    position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)',
                    maxWidth: '920px', width: '100%', lineHeight: 1.4, textAlign: 'center', paddingLeft: '15px', paddingRight: '15px'}}>
                    <div className="notfound-404" style={{position: 'absolute', height: '100px', top: 0, left: '50%', transform: 'translateX(-50%)', zIndex: -1}}>
                        <h1 style={{fontFamily: 'Maven Pro, sans-serif', color: '#ececec', fontWeight: 900, fontSize: '276px', margin: 0, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                            404
                        </h1>
                    </div>
                    <h2 style={{fontFamily: 'Maven Pro, sans-serif', color: '#000', fontWeight: 900, fontSize: '46px', textTransform: 'uppercase', margin: 0}}>
                        We are sorry, Your Company was not found
                    </h2>
                    <p style={{fontFamily: 'Maven Pro, sans-serif', color: '#000', fontWeight: 400, fontSize: '16px', textTransform: 'uppercase', marginTop: '15px'}}>
                        The page you are looking for cannot be located, please ensure that you have set a domain in the CakeBI settings page or have entered the correct domain
                    </p>
                    <a href='https://cakebi.com' style={{fontFamily: 'Maven Pro, sans-serif', fontSize: '14px', textDecoration: 'none', textTransform: 'uppercase', background: '#5864ff', display: 'inline-block', padding: '16px 38px', border: '2px solid transparent', borderRadius: '40px', color: '#fff', fontWeight: 400}}>
                        Return To Homepage
                    </a>
                </div>
            </div>
        </body>

    </html>
);

export default NoDomain;
