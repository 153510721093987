import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

// material-ui
import {
    Box, Button, Grid,
    Link,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';


// project import
import Dot from 'components/@extended/Dot';
import {
    useGetDashboardDataQuery,
    useGetQuicksightConnectionsQuery,
    useUpdateQuicksightDashboardRefreshMutation,
    useCreateUserDashboardRelationMutation, useDeleteQuicksightConnectionMutation
} from "../../store/api";
import AnimateButton from "../../components/@extended/AnimateButton";
import {EditOutlined} from "@ant-design/icons";
import Loader from "../../components/Loader";
import {useTheme} from "@mui/material/styles";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Name'
    },
    {
        id: 'accountId',
        align: 'left',
        disablePadding: true,
        label: 'AWS Account ID'
    },
    {
        id: 'regionName',
        align: 'left',
        disablePadding: true,
        label: 'Region Name'
    },
    {
        id: 'dashboards',
        align: 'left',
        disablePadding: false,
        label: 'Dashboards'
    },
    {
        id: 'edit',
        align: 'left',
        disablePadding: true,
        label: 'Edit'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function ConnectionTableHead({ order, orderBy }) {
    const theme = useTheme();
    return (
        <TableHead style={{backgroundColor: theme.palette.primary.main}}>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{color: theme.palette.primary.contrastText}}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

ConnectionTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| USER TABLE - STATUS ||============================== //

const Connectionstatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Disabled';
            break;
        case 1:
            color = 'success';
            title = 'Active';
            break;
        case 2:
            color = 'success';
            title = 'Active';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color} />
            <Typography>{title}</Typography>
        </Stack>
    );
};

Connectionstatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| USER TABLE ||============================== //

export default function ConnectionsTable() {
    const theme = useTheme();
    const [order] = useState('asc');
    const [orderBy] = useState('trackingNo');
    const [selected] = useState([]);

    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});

    const { data: rows, isLoading: isConnectionsLoading, error: isConnectionsError } = useGetQuicksightConnectionsQuery();
    const { data: dashboards, isLoading: isDashboardDataLoading , error: isDashboardDataError } = useGetDashboardDataQuery();

    const [refreshQuicksightDashboards, { isRefreshLoading: isRefreshUpdating, error: isRefreshError }] = useUpdateQuicksightDashboardRefreshMutation();
    const [deleteQuicksightConnection, {isDeleteLoading: isDeleteLoading} ] = useDeleteQuicksightConnectionMutation();

    React.useEffect(() => {
        if (isRefreshUpdating)
      console.log(isRefreshUpdating);
    },[isRefreshUpdating])

    const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;


    const HandleDeleteOpen = (row) => {
        setCurrentRow(row);
        setDeleteOpen(true);
    };
    const HandleDeleteClose = () => setDeleteOpen(false);

    const HandleOpen = (row) => {
        setCurrentRow(row);
        setOpen(true);
    };


    const HandleClose = () => setOpen(false);

    if (isConnectionsError) {
        return (
            <Box sx={{p: 20, backgroundColor: theme.palette.error.lighter}}>
                <Typography color="error" align="center" variant="h4">
                    An error occurred while fetching data.
                </Typography>
            </Box>
        );
    }

    if (isConnectionsLoading) {
        return <> <Loader /> <h1 align={'center'}>Loading</h1> </>
    }

    return (
        <Box>
            <Modal
                open={open}
                onClose={HandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ pt: 2.25, backgroundColor: "white", width: "90%", margin: "0 auto",
                    marginTop: "100px", padding: "20px", maxWidth: "600px"
                }}>
                    <div>Dashboards</div>
                    <hr/>
                    {dashboards && dashboards.filter(dashboard => dashboard.connection_id == currentRow.id).map(dashboard =>
                        <p> {dashboard.name} </p>
                    )}
                    {!isRefreshUpdating ? (
                    <Button disableElevation
                            disabled={false}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                        refreshQuicksightDashboards(currentRow.id)
                    }}
                    >
                        Refresh
                    </Button>
                    ) : (
                    <Loader />
                    )}
                </Box>
            </Modal>


            <Modal
                open={deleteOpen}
                onClose={HandleDeleteClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ pt: 2.25, backgroundColor: "white", width: "90%", margin: "0 auto",
                    marginTop: "100px", padding: "20px", maxWidth: "600px"
                }}>
                    <br/>

                    <p>Delete Connection</p>
                    <hr/>
                    <h5>Deleting a Connection cannot be undone</h5>
                    <br/>
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="error"
                        onClick={() => {
                        deleteQuicksightConnection(currentRow.id);
                        setDeleteOpen(false);

                    }}
                    >
                        Delete
                    </Button>
                </Box>
            </Modal>
            {isRefreshError && <Box sx={{p: 1, backgroundColor: theme.palette.error.light}}>
                <Typography color="error" align="center" variant="h4">
                    An error occurred while refreshing dashboards. Check this instance has the correct IAM permissions and that this AWS account has a valid Quicksight enterprise account.
                </Typography>
            </Box>}
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <ConnectionTableHead order={order} orderBy={orderBy} />

                    {rows.length === 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={6} sx={{py: 3}}>
                                    <Typography variant="h4" align="center">
                                        No Connections Available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}

                    {rows.length !== 0 && (
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                const isItemSelected = isSelected(row.trackingNo);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.aws_account_id}</TableCell>
                                        <TableCell align="left">{row.region_name}</TableCell>
                                        <TableCell align="left">
                                            <AnimateButton>
                                                <Button onClick={() => HandleOpen(row)}>
                                                    {dashboards && dashboards.filter(dashboard => dashboard.connection_id == row.id).length}
                                                </Button>
                                            </AnimateButton>
                                        </TableCell>
                                        <TableCell>
                                            <AnimateButton>
                                                <Button onClick={() => HandleDeleteOpen(row)}>
                                                    <EditOutlined />
                                                </Button>
                                            </AnimateButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Box>
    );
}
