
// project import
import menu from './menu';
import auth from './auth';
import api from './api';
// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = { menu, auth, ...api };

export default reducers;
