import axios from 'axios';

import { API_URL } from 'config';
const register = (name, email, password, passwordConfirm, company, hostname) => {
    return axios.post(API_URL + 'auth/register/create', {
        name,
        email,
        password,
        passwordConfirm,
        company,
        'verified': false,
        'role': 3,
        hostname
    });
};

const userLogin = (email, password, company_id) => {
    return axios
        .post(API_URL + `auth/login/${company_id}`, {
            email,
            password
        })
        .then((response) => {
            if (response.data.access_token) {
                sessionStorage.setItem('user', JSON.stringify(response.data));
                sessionStorage.setItem("access_token", response.data.access_token);
            }
            return response.data;
        });
};

const adminLogin = (email, password) => {
    return axios
        .post(API_URL + 'auth/login', {
            email,
            password
        })
        .then((response) => {
            if (response.data.access_token) {
                sessionStorage.setItem('user', JSON.stringify(response.data));
                sessionStorage.setItem("access_token", response.data.access_token);
            }
            return response.data;
        });
};

const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
};

const forgotAdmin = (email, hostname) => {
    return axios
        .post(API_URL + 'auth/forgot-password/admin', {
            email,
            hostname
        })
        .then((response) => {
            return response.data;
        });
};

const forgotUser = (email, hostname) => {
    return axios
        .post(API_URL + 'auth/forgot-password/user', {
            email,
            hostname
        })
        .then((response) => {
            return response.data;
        });
};

const authService = {
    register,
    userLogin,
    adminLogin,
    logout,
    forgotAdmin,
    forgotUser
};

export default authService;
